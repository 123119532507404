import React, { Component } from 'react'
import { Badge, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle'

import LoadingDefault from '../../../Elements/Loading/LoadingDefault'
import QueryBuilderCombinator from './QueryCombinator'
import { Link } from 'react-router-dom'

class QueryBuilderLink extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: 'Link',
            uuid: this.props.field.uuid,
            combinators:
                this.props.field && this.props.field.combinators
                    ? this.props.field.combinators
                    : [],
            where_min:
                this.props.field && this.props.field.where_min
                    ? this.props.field.where_min
                    : null,
            where_empty_not_empty:
                this.props.field && this.props.field.where_empty_not_empty
                    ? this.props.field.where_empty_not_empty
                    : null,
            include_archived:
                this.props.field && this.props.field.include_archived
                    ? this.props.field.include_archived
                    : null,
            where_max:
                this.props.field && this.props.field.where_max
                    ? this.props.field.where_max
                    : null,
            where_other_type:
                this.props.field && this.props.field.where_other_type
                    ? this.props.field.where_other_type
                    : null,
            where_other_query:
                this.props.field && this.props.field.where_other_query
                    ? this.props.field.where_other_query
                    : null,
            sellink: null,
            seldata: null,
            dataFields: [],
            linkFields: [],
            queries: [],
            level: this.props.level ?? 0,
        }

        this.addCombinator = this.addCombinator.bind(this)
        this.onNormalChange = this.onNormalChange.bind(this)
        this.handleOnChange = this.handleOnChange.bind(this)
        this.onChangeField = this.onChangeField.bind(this)
        this.onChangeLink = this.onChangeLink.bind(this)
        this.onChangeCombinator = this.onChangeCombinator.bind(this)
        this.onDeleteCombinator = this.onDeleteCombinator.bind(this)
    }

    fetchDataFields(ddId) {
        let params = { params: { defDossier: ddId, limit: 500 } }
        this.setState({ loadingDataFieldItems: true })
        this.props.kedo
            .api()
            .getCached(
                this.props.kedo.api().getDefDossierDefFieldEndpoint(),
                params
            )
            .then((response) =>
                this.setState({
                    dataFields: response.data.results,
                    loadingDataFieldItems: false,
                })
            )
    }

    fetchQueries(ddId) {
        let params = { params: { defDossier: ddId, limit: 500 } }
        this.setState({ loadingQueryItems: true })
        this.props.kedo
            .api()
            .get(this.props.kedo.api().getDossierQueryEndpoint(), params)
            .then((response) =>
                this.setState({
                    queries: response.data.results,
                    loadingQueryItems: false,
                })
            )
    }

    fetchLinkFields(ddId) {
        let params = {
            params: {
                defDossier: ddId,
                environment: this.props.kedo.env().getEnvironment().id,
                limit: 500,
            },
        }
        this.setState({ loadingLinkFields: true })
        this.props.kedo
            .api()
            .get(this.props.kedo.api().getDefDossierLinkEndpoint(), params)
            .then((response) =>
                this.setState({
                    linkFields: response.data.results,
                    loadingLinkFields: false,
                })
            )
    }

    fetchFields() {
        //First node
        if (this.props.defdossier) {
            this.fetchDataFields(this.props.defdossier)
            this.fetchLinkFields(this.props.defdossier)
        } else {
            //From link
            let ddlUrl = this.props.kedo.api().getDefDossierLinkEndpoint()
            this.props.kedo
                .api()
                .get(ddlUrl + '/' + this.props.defdossierlink)
                .then((response) => {
                    this.setState({ name: response.data.name })
                    this.fetchDataFields(response.data.child_def_dossier_id)
                    this.fetchLinkFields(response.data.child_def_dossier_id)
		    this.fetchQueries(response.data.child_def_dossier_id)
                })
                .catch((err) => {
                    this.setState({ error: err.response.status })
                })
        }
    }

    componentDidMount() {
        this.fetchFields()
    }

    onChangeMinOrMax(index, field, fieldName) {
        let fields = this.state.fields
        fields[index] = field
        this.setState({ fields: fields })
        this.handleOnChange()
    }

    onChangeField(index, field) {
        let fields = this.state.fields
        fields[index] = field
        this.setState({ fields: fields })
        this.handleOnChange()
    }

    onChangeCombinator(index, field) {
        let combinators = this.state.combinators
        combinators[index] = field
        this.setState({ combinators: combinators })
        this.handleOnChange()
    }

    onChangeLink(index, field) {
        let links = this.state.links
        links[index] = field
        this.setState({ links: links })
        this.handleOnChange()
    }

    handleOnChange() {
        let link = {
            combinators: this.state.combinators,
        }

        if (this.props.defdossierlink) {
            link.id = this.props.defdossierlink
        }

        link.where_min = this.state.where_min
        link.where_max = this.state.where_max
        link.where_other_query = this.state.where_other_query
        link.where_other_type = this.state.where_other_type
        link.where_empty_not_empty = this.state.where_empty_not_empty
        link.include_archived = this.state.include_archived

        this.props.onChange(this.props.index, link)
    }

    onDeleteCombinator(uuid) {
        let combinators = this.state.combinators.filter(
            (item) => item.uuid !== uuid
        )
        this.setState({ combinators: combinators }, this.handleOnChange)
    }

    addCombinator() {
        let combinators = this.state.combinators
        let newUuid = this.props.kedo.utils().uuidv4()
        combinators.push({
            id: this.props.defdossierlink,
            uuid: newUuid,
        })
        this.setState({ combinators: combinators }, this.handleOnChange)
    }

    onNormalChange(e) {
        this.setState(
            { [e.target.name]: e.target.value.toString() },
            this.handleOnChange
        )
    }

    render() {
        const kedo = this.props.kedo
        return (
            <div
                className={`card mb-3 ${
                    this.props.index % 2 == 0
                        ? 'border-primary'
                        : 'border-secondary border-secondary'
                }`}
            >
                <div className="card-header">
                    <Badge variant={'info'}>
                        {kedo.t('Link')}{' '}
                        {this.props.ddObject
                            ? this.props.ddObject.name_plural
                            : ''}{' '}
                        {this.state.name}
                    </Badge>
                    {this.state.error && this.state.error === 404 ? (
                        <Badge variant={'warning'}>
                            {kedo.t('Item not found')}{' '}
                            <FontAwesomeIcon icon={faExclamationCircle} />
                        </Badge>
                    ) : null}
                    {this.state.error && this.state.error !== 404 ? (
                        <Badge variant={'warning'}>
                            {kedo.t('Something went wrong')}{' '}
                            <FontAwesomeIcon icon={faExclamationCircle} />
                        </Badge>
                    ) : null}
                    {this.state.loadingDataFieldItems ||
                    this.state.loadingLinkFields ||
                    this.state.loadingQueryItems ? (
                        <LoadingDefault as={'span'} />
                    ) : null}
                    <div className="mt-2 d-inline-block form-group">
                        <label>{kedo.t('empty/not-empty')}</label>
                        <select
                            className={
                                'd-inline-block w-auto form-control form-control-sm'
                            }
                            onChange={this.onNormalChange}
                            value={this.state.where_empty_not_empty}
                            name={'where_empty_not_empty'}
                        >
                            <option value={''}>&nbsp;</option>
                            <option value={'EMPTY'}>{kedo.t('EMPTY')}</option>
                            <option value={'NOT_EMPTY'}>
                                {kedo.t('NOT EMPTY')}
                            </option>
                        </select>
                    </div>
                    <div className="ml-2 mt-2 d-inline-block form-group">
                        <label>{kedo.t('Archived')}</label>
                        <select
                            className={
                                'd-inline-block w-auto form-control form-control-sm'
                            }
                            onChange={this.onNormalChange}
                            value={this.state.include_archived}
                            name={'include_archived'}
                        >
                            <option value={''}>{kedo.t('Both')}</option>
                            <option value={'none_archived'}>
                                {kedo.t('None archived')}
                            </option>
                            <option value={'archived'}>
                                {kedo.t('Archived')}
                            </option>
                        </select>
                    </div>
                    <div className="invisible mt-2 d-inline-block form-group">
                        <label>{kedo.t('Minimum')}</label>
                        <input
                            value={this.state.where_min}
                            onChange={this.onNormalChange}
                            type={'text'}
                            name={'where_min'}
                        />
                    </div>
                    <div className="invisible mt-2 d-inline-block form-group">
                        <label>{kedo.t('Maximum')}</label>
                        <input
                            value={this.state.where_max}
                            onChange={this.onNormalChange}
                            type={'text'}
                            name={'where_max'}
                        />
                    </div>
                    <div className="mt-2 d-inline-block form-group">
                        <label>{kedo.t('Where other query')}</label>
                        <select
                            className={
                                'd-inline-block w-auto form-control form-control-sm'
                            }
                            onChange={this.onNormalChange}
                            value={this.state.where_other_type}
                            name={'where_other_type'}
                        >
                            <option value={''}>&nbsp;</option>
                            <option value={'IN'}>{kedo.t('IN')}</option>
                            <option value={'NOT_IN'}>{kedo.t('NOT IN')}</option>
                        </select>
                        <select
                            className={
                                'd-inline-block w-auto form-control form-control-sm'
                            }
                            onChange={this.onNormalChange}
                            value={this.state.where_other_query}
                            name={'where_other_query'}
                        >
                            <option value={''}>&nbsp;</option>
                            {this.state.queries.map((queryItem) => (
                                <option key={queryItem.id} value={queryItem.id}>
                                    {kedo
                                        .env()
                                        .translateItem(
                                            queryItem,
                                            'dossierquery'
                                        )}
                                </option>
                            ))}
                        </select>
                        {this.state.where_other_query && (
                            <Link
                                to={`/querybuilder/${this.state.where_other_query}`}
                                target="_blank"
                            >
                                <Button
                                    variant={'secondary'}
                                    className={'float-right'}
                                    size={'sm'}
                                >
                                    View
                                </Button>
                            </Link>
                        )}
                    </div>

                    <Button
                        variant={'secondary'}
                        className={'float-right'}
                        size={'sm'}
                        onClick={() => this.props.onDelete(this.state.uuid)}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </Button>
                    <Button
                        className={'float-right'}
                        size={'sm'}
                        onClick={this.addCombinator}
                    >
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                </div>
                <div className="card-body">
                    {this.state.combinators.map((field, linkIndex) => (
                        <QueryBuilderCombinator
                            field={field}
                            kedo={kedo}
                            level={this.state.level + 1}
                            key={field.uuid}
                            uuid={field.uuid}
                            defdossierlink={field.id}
                            index={linkIndex}
                            onDelete={this.onDeleteCombinator}
                            onChange={this.onChangeCombinator}
                            linkField={this.state.linkFields.find(
                                (linkItem) => linkItem.id === field.id
                            )}
                        />
                    ))}
                </div>
            </div>
        )
    }
}

export default QueryBuilderLink
